"use client"

import React, { useEffect, useRef } from "react"
import createGlobe from "cobe"

import { cn } from "@/lib/utils"
import { Badge } from "@/components/ui/badge"

export function FeaturesSectionDemo() {
  const features = [
    {
      title: "Biolink Merger",
      description: "Merge all your biolinks into a cohesive, branded page.",
      skeleton: <SkeletonOne />,
      className: "col-span-1 sm:col-span-3 border-b lg:border-none",
      status: "Live",
      badgeClass: "bg-purple-300",
    },
    {
      title: "Digital Product Sales",
      description: "Sell your digital downloads directly from your link page",
      skeleton: <SkeletonTwo />,
      className: "col-span-1 sm:col-span-3 border-b lg:border-none",
      status: "Coming Soon",
      badgeClass: "bg-black dark:bg-white",
    },
    {
      title: "Tips and Donations",
      description:
        "Connect with your audience by offering tips and accepting donations.",
      skeleton: <SkeletonThree />,
      className: "col-span-1 sm:col-span-3 border-b lg:border-none",
      status: "Coming Soon",
      badgeClass: "bg-black dark:bg-white",
    },
    {
      title: "Bookings and Payments",
      description:
        "Offer bookings for services and collect payments seamlessly",
      skeleton: <SkeletonFour />,
      className: "col-span-1 sm:col-span-3 border-b lg:border-none",
      status: "Coming Soon",
      badgeClass: "bg-black dark:bg-white",
      // badgeClass: "bg-blue-400",
    },
  ]
  return (
    <div className="relative z-20 mx-auto max-w-7xl py-10 lg:py-40">
      <div className="px-8">
        <h4 className="mx-auto max-w-5xl text-center text-3xl font-medium tracking-tight text-black dark:text-white lg:text-5xl lg:leading-tight">
          Packed with thousands of features
        </h4>

        <p className="mx-auto my-4 max-w-2xl text-center text-sm font-normal text-neutral-500 dark:text-neutral-300 lg:text-base">
          From creating your online presence and profile to managing your
          business. All in one place.
        </p>
      </div>

      <div className="relative">
        <div className="mt-12 grid grid-cols-1 rounded-md dark:border-neutral-800 sm:grid-cols-6 xl:border">
          {features.map((feature) => (
            <FeatureCard key={feature.title} className={feature.className}>
              <FeatureTitle>
                <div className="pr-2">{feature.title}</div>
                <Badge className={feature.badgeClass}>{feature.status}</Badge>
              </FeatureTitle>
              <FeatureDescription>{feature.description}</FeatureDescription>
              <div className="size-full">{feature.skeleton}</div>
            </FeatureCard>
          ))}
        </div>
      </div>
    </div>
  )
}

const FeatureCard = ({
  children,
  className,
}: {
  children?: React.ReactNode
  className?: string
}) => {
  return (
    <div className={cn(`relative overflow-hidden p-4 sm:p-8`, className)}>
      {children}
    </div>
  )
}

const FeatureTitle = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div className="mx-auto flex max-w-5xl text-left text-xl tracking-tight text-black dark:text-white md:text-2xl md:leading-snug">
      {children}
    </div>
  )
}

const FeatureDescription = ({ children }: { children?: React.ReactNode }) => {
  return (
    <p
      className={cn(
        "mx-auto max-w-4xl text-sm md:text-base",
        "text-left font-normal text-neutral-500 dark:text-neutral-300",
        "my-2"
      )}
    >
      {children}
    </p>
  )
}

export const SkeletonOne = () => {
  return (
    <div className="relative mb-5 mt-10 flex flex-col items-center bg-transparent dark:bg-transparent">
      <img
        src="https://orion.themergie.com/static/biolink.jpg"
        alt="header"
        className="aspect-square size-full rounded-2xl object-cover object-left-top"
      />
    </div>
  )
}

export const SkeletonTwo = () => {
  return (
    <div className="relative mb-5 mt-10 flex flex-col items-center bg-transparent dark:bg-transparent">
      <img
        src="https://orion.themergie.com/static/digital-product.jpg"
        alt="header"
        className="aspect-square size-full rounded-2xl object-cover object-left-top"
      />
    </div>
  )
}

export const SkeletonThree = () => {
  return (
    <div className="relative mb-5 mt-10 flex flex-col items-center bg-transparent dark:bg-transparent">
      <img
        src="https://orion.themergie.com/static/tips.jpg"
        alt="header"
        className="aspect-square size-full rounded-2xl object-cover object-left-top"
      />
    </div>
  )
}

export const SkeletonFour = () => {
  return (
    <div className="relative mb-5 mt-10 flex flex-col items-center bg-transparent dark:bg-transparent">
      <img
        src="https://orion.themergie.com/static/booking-payment.jpg"
        alt="header"
        className="aspect-square size-full rounded-2xl object-cover object-left-top"
      />
    </div>
  )
}

export const Globe = ({ className }: { className?: string }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    let phi = 0

    if (!canvasRef.current) return

    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 2,
      width: 600 * 2,
      height: 600 * 2,
      phi: 0,
      theta: 0,
      dark: 1,
      diffuse: 1.2,
      mapSamples: 16000,
      mapBrightness: 6,
      baseColor: [0.3, 0.3, 0.3],
      markerColor: [0.1, 0.8, 1],
      glowColor: [1, 1, 1],
      markers: [
        // longitude latitude
        { location: [37.7595, -122.4367], size: 0.03 },
        { location: [40.7128, -74.006], size: 0.1 },
      ],
      onRender: (state) => {
        // Called on every animation frame.
        // `state` will be an empty object, return updated params.
        state.phi = phi
        phi += 0.01
      },
    })

    return () => {
      globe.destroy()
    }
  }, [])

  return (
    <canvas
      ref={canvasRef}
      style={{ width: 600, height: 600, maxWidth: "100%", aspectRatio: 1 }}
      className={className}
    />
  )
}
