"use client"

import { SignInButton, useAuth } from "@clerk/nextjs"

import { Button } from "@/components/ui/button"

export const SignIn = () => {
  const auth = useAuth()

  return (
    <>
      {!auth.isSignedIn && (
        <Button asChild>
          <SignInButton />
        </Button>
      )}
    </>
  )
}
